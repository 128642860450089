import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

  title = 'WEBSYSFAN';
  logo = '../../../assets/img/logo-circle.png';
  flyer = '../../../assets/img/apps/apps.jpg';
  app = '../../../assets/img/apps/aplicaciones.jpg';

  pc = '../../../assets/icons/pc.png';
  movile = '../../../assets/icons/moviles.png';
  car = '../../../assets/icons/car.png';
  personalizada = '../../../assets/icons/personalizada.png';
  education = '../../../assets/icons/education.png';
  juegos = '../../../assets/icons/juegos.png';
  intranet = '../../../assets/icons/intranet.png';
  business = '../../../assets/icons/business.png';


  contratarnos = '../../../assets/svg/contrtarnos.svg';



  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  constructor() { }

  ngOnInit(): void {
  }

}
