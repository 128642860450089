import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss']
})
export class WebComponent implements OnInit {

  flyer = '../../../assets/img/web/web.jpg';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%2deseo%20información%20de:';

  wow = 'wow';
  down  = 'fadeInRight';
  left  = 'fadeInRight';
  right  = 'fadeInRight';
  up  = 'fadeInRight';
  time = `data-wow-duration="1s"`;
  // right  fadeInRight" data-wow-duration="1s" data-wow-delay=".2s;

  web = '../../../assets/img/web/webs-que-desarrollamos.jpg';

  constructor() { }

  ngOnInit(): void {
  }

}
