
<div class="mt-5 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="1.6s"><h3>STREAMING MÁS PÁGINA WEB</h3></div>
<hr class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.6s">
<div class="host">
  <div class="boxHost wow fadeInLeft" data-wow-duration=".8s"
    *ngFor="let h of host">
    <h5 class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s">{{ h.title }}</h5>
    <h4 class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">USD<span> {{ h.price }} </span>año</h4>
    <hr>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".1s"><i class="fas fa-check-circle"></i> {{ h.oyentes }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".2s"><i class="fas fa-check-circle"></i> {{ h.web }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s"><i class="fas fa-check-circle"></i> {{ h.diseno }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=" .35s"><i class="fas fa-check-circle"></i> {{ h.adaptable }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".4s"><i class="fas fa-check-circle"></i> {{ h.dominio }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".5s"><i class="fas fa-check-circle"></i> {{ h.subdominios }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".6s"><i class="fas fa-check-circle"></i> {{ h.cpanel }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".7s"><i class="fas fa-check-circle"></i> {{ h.email }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".8s"><i class="fas fa-check-circle"></i> {{ h.almacenamiento }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".9s"><i class="fas fa-check-circle"></i> {{ h.sofware }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.1s"><i class="fas fa-check-circle"></i> {{ h.seo }}</p>
    <!-- <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.2s"><i class="fas fa-check-circle"></i> {{ h.marketing }}</p> -->
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.4s"><i class="fas fa-check-circle"></i> {{ h.app }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.5s"><i class="fas fa-check-circle"></i> {{ h.capacitacion }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.6s"><i class="fas fa-check-circle"></i> {{ h.Soporte }}</p>
    <div class="text-center mt-3">
      <a class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" href="{{ h.url }}" class="" target="_blank">Adquirir ya!</a>
    </div>
  </div>
  
</div>
<br>
<br>

<div class="mt-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="1.6s"><h3>STREAMING DE AUDIO</h3></div>
<hr class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.6s">

<div class="host">
  <div class="boxHost wow fadeInLeft" data-wow-duration=".8s"
    *ngFor="let p of planes">
    <h5 class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s">{{ p.title }}</h5>
    <div class="price">
      <h3 class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">USD<span>{{ p.mounth }} </span>Mensual</h3>
      <h4 class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">USD<span>{{ p.year }} </span>Anual</h4>

    </div>
    <hr>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".1s"><i class="fas fa-check-circle"></i> {{ p.oyentes }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".2s"><i class="fas fa-check-circle"></i> {{ p.autoDj }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s"><i class="fas fa-check-circle"></i> {{ p.kbps }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=" .35s"><i class="fas fa-check-circle"></i> {{ p.sofware }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".4s"><i class="fas fa-check-circle"></i> {{ p.reproductor }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".5s"><i class="fas fa-check-circle"></i> {{ p.app }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".6s"><i class="fas fa-check-circle"></i> {{ p.capacitacion }}</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".7s"><i class="fas fa-check-circle"></i> {{ p.Soporte }}</p>
    <div class="text-center mt-3">
      <a class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" href="{{ p.url }}" class="" target="_blank">Adquirir ya!</a>
    </div>
  </div>
  
</div>
<br>
<br>

<h3 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay="1s">Todos nuestros planes incluyen </h3>
<hr class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.6s">

<div class="incluye">
  <div class="boxIncluye wow fadeInLeft" data-wow-duration="1.4"
  *ngFor="let i of incluye">
  <p class="wow fadeInRight" data-wow-duration="1.6s" data-wow-delay="1.6s"><i class="fas fa-check-circle"></i> {{ i.name }}</p>

  </div>
  <p class="wow fadeInRight" data-wow-duration="1.6s" data-wow-delay="1.8s">Ponte en contacto con nosotros para brindarte una asesoría personalizada</p>
</div>
    

















