<div class="host">
  <div class="boxHost wow fadeInLeft" data-wow-duration=".8s"
    *ngFor="let c of packs">
    <h5 class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s">{{ c.title }}</h5>
    <h4 class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">USD<span> {{ c.price }} </span>año</h4>
    <hr>
    
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".1s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.domain }}</li>
    </p>

    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.cpanel }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.diseno }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.mail }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.space }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".6s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.subdomain }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".7s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.certificate }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".8s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.adaptable }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".9s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.gallery }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.video }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1.1s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.links }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".1s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.maintenance }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.form }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.map }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.products }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">
      <i class="fas fa-check-circle ">&nbsp;</i>    
      <li>{{ c.car }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".6s">
      <i class="fas fa-check-circle ">&nbsp;</i>    
      <li>{{ c.pasarela }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".7s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.pages }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".8s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.panel }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".9s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.time }}</li>
    </p>
    <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
      <i class="fas fa-check-circle ">&nbsp;</i>
      <li>{{ c.capacitation }}</li>
    </p>
  
    
    <div class="text-center mt-3">
      <a class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" href="{{ c.url }}" class="">Adquirir ya!</a>
    </div>

  </div>
</div>


