import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.scss']
})
export class HostComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  host: any = [
    {
      title: 'Personal',
      price: '20',
      espacio: '256 MB',
      email: '10 ',
      dominios: '0 ',
      db: '1',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20PERSONAL:',
    },
    {
      title: 'Básico',
      price: '30',
      espacio: '512 MB',
      email: '20 ',
      dominios: '1 ',
      db: '3',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20BÁSICO:',
    },
    {
      title: 'Estándar',
      price: '40',
      espacio: '1 GB',
      email: '30 ',
      dominios: '2 ',
      db: '4',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20ESTÁNDAR',
    },
    {
      title: 'Corporativo',
      price: '70',
      espacio: '5 GB',
      email: '100 ',
      dominios: '3 ',
      db: '6',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20CORPORATIVO',
    },
    {
      title: 'Premium',
      price: '100',
      espacio: '10 GB ',
      email: '300 ',
      dominios: '5 ',
      db: '8',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20PREMIUM',
    },
    {
      title: 'Empresarial',
      price: '150',
      espacio: '20 GB',
      email: '1000',
      dominios: '8 ',
      db: '12',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20EMPRESARIAL',
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
