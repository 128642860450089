import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AppsComponent } from './pages/apps/apps.component';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';
import { HomeComponent } from './pages/home/home.component';
import { HostingComponent } from './pages/hosting/hosting.component';
import { StreamingAudioComponent } from './pages/streaming-audio/streaming-audio.component';
import { StreamingVideoComponent } from './pages/streaming-video/streaming-video.component';
import { WebComponent } from './pages/web/web.component';


const routes: Routes = [
  // ROUTS PAGES 
  { path: '', component: HomeComponent },
  { path: 'hosting', component: HostingComponent },
  { path: 'apps', component: AppsComponent },
  { path: 'ecommerce', component: EcommerceComponent },
  
  
  
  // ROUTS PAGES SECUNDARY 
  { path: 'webs', component: WebComponent },
  { path: 'streaming-audio', component: StreamingAudioComponent },
  { path: 'streaming-video', component: StreamingVideoComponent },
  
  // { path: 'webs', component: WebComponent },

  {path: '**', pathMatch: 'full', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: true,
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
