import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  flyer = '../../../assets/img/home/creacion-paginas-web.jpg';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%2deseo%20información%20de:';
  pay = '../../../assets/img/hosting/pago-hosting.jpg';

  constructor() { }

  ngOnInit(): void {
  }

}
