import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {

  title: string;
  flyer: string;
  ws: string;

  constructor() {
    this.title= 'WEBSYSFAN';
    this.flyer = '../../../assets/img/ecommerce/ecommerce.jpg';
    this.ws = 'https://wa.me/51916949264?text=Hola%2deseo%20información%20de:';
  }

  ngOnInit(): void {
  }

}
