import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['./packs.component.scss']
})
export class PacksComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  packs: any = [
    {
      img: '../../../assets/img/packs/eccomerce.jpg',
      link: '/ecommerce',
      title: 'Ecommerce',
      des: 'Ten tu tienda en línea y véndele al mundo tus servicios o tus productos y recibe el dinero en tu banco o en el sistema que más gustes.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20TIENDA%20ONLINE',
    },
    {
      img: '../../../assets/img/packs/diseno-pagina-web.jpg',
      link: '/webs',
      title: 'Desarrollo web',
      des: 'Todos nuestros diseños son elaborados de acuerdo a tus necesidades y adaptables a todos los dispositivos.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20DESARROLLO%20WEB',
    },
    {
      img: '../../../assets/img/packs/aplicaciones.jpg',
      link: '/apps',
      title: 'Aplicaciones',
      des: 'Con la revolución de la tecnología las Aplicaciones móviles sean han convertido en tu mejor socio para alcanzar tus objetivos.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20APLICACIONES',
    },
    {
      img: '../../../assets/img/packs/sistemas.jpg',
      link: '',
      title: 'Sistemas',
      des: 'Creamos sistemas autoadministrables de acuerdo a tus requerimientos, haciendo uso de los últimos avances en los lenguajes de programación.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20SISTEMAS',
    },
    {
      img: '../../../assets/img/packs/radio-online.jpg',
      link: '/streaming-audio',
      title: 'Emisora Online',
      des: 'Ten tu propia emisora en línea y transmite su contenido a miles de usuarios en la red, con una alta fidelidad del sonido en formato MP3 o AAC+ utilizando nuestro servicio con AutoDJ o Emitiendo en Vivo.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20EMISORA%20ONLINE',
    },
    {
      img: '../../../assets/img/packs/video-streaming.jpg',
      link: '/streaming-video',
      title: 'Streaming de Video',
      des: 'Por medio de la página web puedes tener tu canal de TV ONLINE, en la cual puedes emitir tus seminarios, eventos, capacitaciones o conferencias en vídeo en línea a una gran audiencia. Garantizamos calidad en la señal y acceso desde múltiples dispositivos.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20TV%20-%20ONLINE',
    },    
    {
      img: '../../../assets/img/packs/redes-sociales.jpg',
      link: '',
      title: 'Redes Sociales',
      des: 'En la actualidad, no es suficiente con tener un producto excelente, una página web increíble o un blog super interesante con contenido nuevo cada tercer día. Si nadie lo ve, no te servirá de nada. Es por eso que cada vez es más importante para las empresas tener una campaña de mercadotecnia exitosa con un manejo de redes sociales impecable.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20REDES%20SOCIALES',
    },
    {
      img: '../../../assets/img/packs/email-corporativo.jpg',
      link: '',
      title: 'Emails Corporativos',
      des: 'Crea emails corporativos para tus campañas publicitarias, de una manera muy fácil y rápido en el cPanel de nuestro hosting.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20EMAIL%20CORPORATIVO',
    },
    {
      img: '../../../assets/img/packs/diseno-grafico.jpg',
      link: '',
      title: 'Diseño Gráfico',
      des: 'Contamos con profesionales con amplio conocimiento en las últimas técnicas de diseño gráfico y la utilización de última tecnología para cada proyecto, garantizamos la calidad de nuestro trabajo.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20DISEÑO%20GRÁFICO',
    },
    {
      img: '../../../assets/img/packs/dominios.jpg',
      link: '',
      title: 'Registro de Dominios',
      des: 'Adquiera el dominio que se acople a tus necesidades al mejor precio del mercado de forma confiable y sugura. brindamos un asesoramiento para que tu dominio sea fácil de recordar para tus Clientes.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20REGISTRO%20DE%20DOMINIOS',
    },
    {
      img: '../../../assets/img/packs/hosting.jpg',
      link: '/hosting',
      title: 'Hosting',
      des: 'Contamos con tecnologia de última generación, es por ello que nuestros servicios estan entre los más rápidos y eficientes de la red.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20HOSTING',
    },
    {
      img: '../../../assets/img/packs/ingresar-a-cPanel.jpg',
      link: '/hosting',
      title: 'cPanel Personal',
      des: 'Tenemos un cPanel de control intuitivo y poderoso que esta incluido en todos nuestros paquetes de hosting. Con cPanel es muy fácil administrar tus servicios alojados.',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20información:%20-%20CPANEL%20PERSONAL',
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
