import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';


  routes: any = [
    { name: 'Home', path: '/home' },
    { name: 'Audio',   path: '/streaming-audio' },
    { name: 'Video',   path: '/streaming-video' },
    { name: 'Ecommerce', path: '/ecommerce' },
    { name: 'Hosting', path: '/hosting' },
    { name: 'Apps',  path: '/apps' },
    { name: 'Webs',   path: '/webs' },
    // { name: 'Sistemas',   path: '/systems' },
  ];

  drop: any = [
    // { name: 'Ecommerce', path: '/ecommerce' },
    { name: 'Streaming Video',   path: '/streaming-video' },
    { name: 'Streaming Audio',   path: '/streaming-audio' },
    { name: 'Webs',   path: '/webs' },
    { name: 'APPS', path: '/apps' },
    
    
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
