import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hosting',
  templateUrl: './hosting.component.html',
  styleUrls: ['./hosting.component.scss']
})
export class HostingComponent implements OnInit {

  flyer = '../../../assets/img/hosting/hosting.jpg';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%2deseo%20información%20de:';

  hosting = '../../../assets/img/hosting/hosting-baratos.jpg';
  pay = '../../../assets/img/hosting/pago-hosting.jpg';

  constructor() { }

  ngOnInit(): void {
  }

}
