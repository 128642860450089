<div class="audio default">
  <div class="video">
    <div class="text">
      <h3>Steaming de Audio</h3>
      <a class="btn btn-primary btn-lg" href="{{ ws }}" target="_blank">Contacto</a>
    </div>

    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="{{ video }}" type="video/mp4">
    </video>

  </div>
</div>

<main class="main">
  <section class="section">
    
    <app-pack-audio></app-pack-audio>
    

  </section>
</main>

