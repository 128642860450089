<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="flyer" alt="Flyer {{ title }}">
</div>

<main class="main">
  <section class="section">

		<h3 class="mt-5 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">
		<span>Diseñamos tu página web</span> de acuerdo a tus necesidades</h3>

		<hr class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">

		<div class="df">
      <i class="fas fa-crutch wow {{ right }} {{ time }}"></i>
      <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">Cada proyecto es único y muy personal, es por ello que para cada <strong>Cliente</strong> elaboramos un <span>diseño personalizado y adaptable</span> cumpliendo con los estandares exigidos actualmente por la industria de internet.</p>
    </div>

    <div class="df">
      <i class="fas fa-paint-roller wow {{ right }} {{ time }}"></i>
      <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">El <span>Diseño de página web</span> de tu emprendimiento es la carta de presentación ante el mundo.</p>
    </div>

    <div class="df">
      <i class="fas fa-check-square wow {{ right }} {{ time }}"></i>
      <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">La <span>calidad de tu sitio web</span> debe reflejar la calidad de tus productos o servicios.</p>
    </div>

    <div class="df">
      <i class="fas fa-calendar-alt wow {{ right }} {{ time }}"></i>
      <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s"><span>Planificamos, diseñamos y desarrollamos la página web</span> que se ajusta a tus necesidades.</p>
    </div>
      <div class="df">
        <i class="fas fa-user-check wow {{ right }} {{ time }}"></i>
        <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">Combinando <span>estrategia, creatividad y diseño</span> creamos páginas web con tecnologías actuales y bien posicionadas para ser detectadas por los buscadores.</p>
      </div>
	</section>

  <div class=" metologia">
    <section class="section">
      <h2 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">Metodología de trabajo que utilizamos</h2>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>1</p></div>
        <div>
          <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">PLANIFINICIÓN DEL PROYECTO</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">Definimos los objetivos del diseño y las tecnologías que se van utilizar. Establecemos un calendario con fechas precisas de entrega y pasos a seguir en el desarrollo del proyecto. De acuerdo al contenido que va a tener internamente, se organiza la información para que quede distribuida adecuadamente dentro de cada una de las páginas que va a tener el sitio, consiguiendo de esta manera una navegación fácil e intuitiva para el usuario.</p>
        </div>
      </div>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>2</p></div>
        <div>
          <h3  class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">DISEÑO Y USABILIDAD</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">Trabajamos un diseño gráfico personalizado ajustado a tus objetivos. Se trata de crear una experiencia visual muy rica y atractiva para el usuario, en la cual comunica los valores de marca e identidad corporativa. Cada elemento será diseñado pensando en la máxima usabilidad y accesibilidad para el usuario.</p>
        </div>
      </div>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>3 </p></div>
        <div>
          <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">DESARROLLO DE LA WEB</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s"> La estructura, apariencia y funcionalidades del sitio están completamente definidas por lo que procedemos con la codificación. Desarrollamos el sitio web de forma que sea lo más veloz posible, tenga un buen posicionamiento en buscadores y funcione correctamente en todos los navegadores.</p>
        </div>
      </div>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>4</p></div>
        <div>
          <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">CONTROL DE CALIDAD</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">Para hacer las pruebas correspondientes, el proyecto se sube en uno de nuestros servidores con acceso restringido.</p>
        </div>
      </div>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>5</p></div>
        <div>
          <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">ENTREGA DEL PROYECTO</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">Cuando el sitio web esté completamente testeado y finalizado, se sube en el hosting y lo hacemos de acceso público. En el momento de la entrega, el proyecto estará completamente optimizado a nivel de posicionamiento SEO y usabilidad.</p>
        </div>
      </div>

      <div class="df dfq">
        <div class="number wow {{ right }} {{ time }}"><p>6</p></div>
        <div>
          <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">CAPACITACIÓN Y SEGUIMIENTO</h3>
          <p class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s"> A continuación, si tu deseas, podemos hacer un seguimiento del posicionamiento y la usabilidad del sitio, usando diversas herramientas de análisis, de esta manera nos aseguramos que aunque pase el tiempo tu web siga estando bien adaptada y posicionada. El día de lanzamiento de tu proyecto te ofrecemos una capacitación completamente gratis para que tu puedas gestionar tu sitio web.</p>
        </div>
      </div>
    </section>
  </div>

  <div class="flyer">
    <img class="wow slideInDown" data-wow-duration="1.5s" [src]="web" alt="Web que desarrollamos {{ title }}">
  </div>


</main>

