import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { PacksComponent } from './packs/packs.component';
import { HostComponent } from './host/host.component';
import { PackAudioComponent } from './pack-audio/pack-audio.component';
import { PackVideoComponent } from './pack-video/pack-video.component';
import { PackEmpresarialesComponent } from './pack-empresariales/pack-empresariales.component';



@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    WhatsappComponent,
    PacksComponent,
    HostComponent,
    PackAudioComponent,
    PackVideoComponent,
    PackEmpresarialesComponent
  ],
  exports: [
    NavComponent,
    FooterComponent,
    WhatsappComponent,
    PacksComponent,
    HostComponent,
    PackAudioComponent,
    PackVideoComponent,
    PackEmpresarialesComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class ComponentsModule { }
