<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="flyer" alt="Flyer {{ title }}">
</div>

<main class="main">
  <section class="section">
    <h3 class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">Tenemos el hosting a la medida tus necesidades</h3>

    <app-host></app-host>
  </section>

  <div class="flyer">
    <img class="wow slideInDown" data-wow-duration="1.5s" [src]="hosting" alt="Flyer {{ title }}">
  </div>

  <div class="flyer">
    <img class="wow slideInDown" data-wow-duration="1.5s" [src]="pay" alt="Flyer {{ title }}">
  </div>


</main>
