<div class="host">
  <div class="boxHost wow fadeInLeft" data-wow-duration=".8s"
    *ngFor="let h of host">
    <h5 class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s">{{ h.title }}</h5>
    <h4 class="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s">USD<span> {{ h.price }} </span>año</h4>
    <hr>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".1s"><i class="fas fa-check-circle"></i> <b> {{ h.espacio }}</b> de espacio</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".2s"><i class="fas fa-check-circle"></i> <b> {{ h.email }}</b> Cuentas Email</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s"><i class="fas fa-check-circle"></i> <b> {{ h.dominios }}</b> Dominios adicionales</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".4s"><i class="fas fa-check-circle"></i> <b> Subdominios</b> ilimitados</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".5s"><i class="fas fa-check-circle"></i> <b> {{ h.db }}</b>  Bases de datos</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".6s"><i class="fas fa-check-circle"></i> Certficado <b> SSL</b> </p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".7s"><i class="fas fa-check-circle"></i> <b> cPanel</b> personal</p>
    <p class="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".8s"><i class="fas fa-check-circle"></i> <b> Soporte</b> 24/7</p>
    <div class="text-center mt-3">
      <a class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" href="{{ h.url }}" class="">Adquirir ya!</a>
    </div>
  </div>
</div>
