import { Component, OnInit } from '@angular/core';

import { Packs } from 'src/app/interfaces/packs';


@Component({
  selector: 'app-pack-empresariales',
  templateUrl: './pack-empresariales.component.html',
  styleUrls: ['./pack-empresariales.component.scss']
})
export class PackEmpresarialesComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  packs: Packs[] = [];

  constructor() { }

  ngOnInit(): void {
  
    this.packs = [
      {
        title: 'Emprendedor',
        price: '150',

        domain: 'tudominio.com',
        cpanel: 'cPanel personal',
        diseno: 'Diseño personalizado',
        mail: '2 E-Mails corporativos',
        space: '2Gb de espacio',
        subdomain: 'Subdominios ilimitados',
        certificate: 'Certificado SSL',
        adaptable: 'Adaptable a multipantalla',
        gallery: 'Galería de imágenes',
        video: 'Video',
        links: 'Enlaces a redes sociales',
        maintenance: 'Mantenimiento',
        form: 'formulario de contacto',
        map: 'Mapa de geolocalización',
        products: 'Se carga 10 productos',
        car: 'Carrito de compras',
        pasarela: 'Pasarela de pagos',
        pages: '3 Paginas internas',
        panel: 'Panel de administración',
        time: 'Tiempo de entrega 15 días',
        capacitation: '1 hora de capacitación online y uso básico de la plataforma',
        url: 'https://wa.me/51916949264?text=Hola%20deseo%20información%20del%20PACK%20EMPRENDEDOR:',
      },
      {
        title: 'MYPYMES',
        price: '200',

        domain: 'tudominio.com',
        cpanel: 'cPanel personal',
        diseno: 'Diseño personalizado',
        mail: '3 E-Mails corporativos',
        space: '3Gb de espacio',
        subdomain: 'Subdominios ilimitados',
        certificate: 'Certificado SSL',
        adaptable: 'Adaptable a multipantalla',
        gallery: 'Galería de imágenes',
        video: 'Video',
        links: 'Enlaces a redes sociales',
        maintenance: 'Mantenimiento',
        form: 'formulario de contacto',
        map: 'Mapa de geolocalización',
        products: 'Se cargan 15 productos',
        car: 'Carrito de compras',
        pasarela: 'Pasarela de pagos',
        pages: '4 Paginas internas',
        panel: 'Panel de administración',
        time: 'Tiempo de entrega 15 días',
        capacitation: '1/2 de capacitación online y uso básico de la plataforma',
        url: 'https://wa.me/51916949264?text=Hola%20deseo%20información%20del%20PACK%20EMPRENDEDOR:',
      },
      {
        title: 'Pymes',
        price: '300',

        domain: 'tudominio.com',
        cpanel: 'cPanel personal',
        diseno: 'Diseño personalizado',
        mail: '10 E-Mails corporativos',
        space: '5Gb de espacio',
        subdomain: 'Subdominios ilimitados',
        certificate: 'Certificado SSL',
        adaptable: 'Adaptable a multipantalla',
        gallery: 'Galería de imágenes',
        video: 'Video',
        links: 'Enlaces a redes sociales',
        maintenance: 'Mantenimiento',
        form: 'formulario de contacto',
        map: 'Mapa de geolocalización',
        products: 'Se carga 20 productos',
        car: 'Carrito de compras',
        pasarela: 'Pasarela de pagos',
        pages: '6 Paginas internas',
        panel: 'Panel de administración',
        time: 'Tiempo de entrega 15 días',
        capacitation: '2 hora de capacitación online y uso básico de la plataforma',
        url: 'https://wa.me/51916949264?text=Hola%20deseo%20información%20del%20PACK%20PYMES:',
      },
    ]
  }



}
