import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-streaming-video',
  templateUrl: './streaming-video.component.html',
  styleUrls: ['./streaming-video.component.scss']
})
export class StreamingVideoComponent implements OnInit {

  flyer = '../../../assets/img/home/creacion-paginas-web.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20del%20STREAMING%20de%20VIDEO:';

  // video: string = '../../../assets/video/video_straming.mp4';

  video: any;

  constructor() {
    this.video = 'assets/video/video_straming.mp4';
  }

  ngOnInit(): void {
  }

}
