<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="flyer" alt="Flyer {{ title }}">
</div>
<app-pack-empresariales></app-pack-empresariales>

<div class="ecommerce">
  <h3 >Preguntas frecuentes</h3>
  
  <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
          <h5>¿Es muy difícil?</h5>
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body"><p> Es muy fácil administrar tu tienda online, no se requiere tener conocimientos avanzados de tecnología, todo es muy intuitivo, el objetivo es que tu te enfoques en vender tus productos y/o servicios.</p></div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          <h5>¿Se pueden moficar los productos?</h5>
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <p>Si, tú puedes administrar tu tienda online de acuerdo a tus necesidades y las veces que lo llegues a necesitar. Además, puedes:</p>
          <li>Subir productos nuevos, </li>
          <li>Cambiar los precios,</li>
          <li>Escribir los detalles del producto,</li>
          <li>Organizarlos por categorías,</li>
          <li>Subir imagenes personalizadas de cada producto,</li>
          <li>etc.</li>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
          <h5>¿Qué sucede al culminar el año?</h5>
        </button>
      </h2>
      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <p>Antes de culminar el año, se te hace llegar un email, con los datos de renovación, dependiendo del servicio contratado tiene una inversión diferente, los precios de renovación son los siguientes:</p>
          <li><b>Emprendedor: </b> USD 70 año </li>
          <li><b>Mypymes: </b> USD 100 año </li>
          <li><b>Pymes: </b> USD 130 año </li>
        </div>
      </div>
    </div>
  </div>

</div>

<br>
<br>
<br>
<br>
<br>
<br>