<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="flyer" alt="Flyer {{ title }}">
</div>

  <div class="sect1">

    <h2 class="text-white wow slideInUp" data-wow-duration="1.6s">Herramientas Profesionales</h2>
    <h3 class="mt-3 wow slideInDown" data-wow-duration="1.4s"><small> Ten tu tienda online ahora mismo y vende tus productos y/o servicios </small></h3>
  </div>

  <app-pack-empresariales></app-pack-empresariales>

  <div class="sect1">

    <h1 class="text-white wow slideInUp" data-wow-duration="1.6s">{{ title }}</h1>
    <h3 class="mt-3 wow slideInDown" data-wow-duration="1.4s"><small> Tenemos lo que estás buscando </small> <br>
      para llegar a nuevos clientes
    </h3>
  </div>

<main class="main">
  <section class="section">
    <app-packs></app-packs>
  </section>

</main>

<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="pay" alt="Flyer {{ title }}">
</div>




