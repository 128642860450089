import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pack-video',
  templateUrl: './pack-video.component.html',
  styleUrls: ['./pack-video.component.scss']
})
export class PackVideoComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  host: any = [
   
    {
      title: ' Básico',
      price: ' 200',
      oyentes: ' 500 Televidentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 512Mb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20BÁSICO',
    },

    {
      title: ' Estándar',
      price: ' 320',
      oyentes: ' 1.200 Televidentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 1Gb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20ESTÁNDAR',
    },

    {
      title: ' Corporativo',
      price: ' 590',
      oyentes: ' 10.000 Televidentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 2gB de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20CORPORATIVO',
    },

    {
      title: ' Deluxe',
      price: ' 990',
      oyentes: ' 50.000 Televidentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 3Gb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20DELUXE',
    },

  ]

  incluye = [
    {name: 'Configuración de transmisión, remotamente'},
    {name: 'Instalacción herramientas de streaming'},
    {name: 'Posicionamiento SEO'},
    {name: 'Diseño 4 de Banners de página web'},
    {name: 'Diseño 3 de Banners para la Playstore'},
    {name: 'Administración Playstore'},
    {name: 'Certificado SSL'},
    {name: 'Enlace redes sociales'},
    {name: 'Mantenimiento'},
    {name: 'Cuentas FTP'},
    {name: 'Reproductor HTML'},
    {name: 'Ancho de Banda ILIMITADO'},
    // {name: ''},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
