<div class="packs">

  <div class="boxPacks wow slideInLeft" data-wow-duration=".8s" *ngFor="let pack of packs">

    <div class="btop">
      <a [routerLink]="pack.link">
        <h5 class="wow slideInRight" data-wow-duration="2s"><b>{{ pack.title }}</b></h5>
        <img [src]="pack.img" [alt]="pack.title" class="wow slideInRight" data-wow-duration="1.7s">
        <p class="wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">{{ pack.des | slice: 0:120 }}</p>
      </a>
    </div>

    <div class="bott">
      <a target="_blank" class="wow slideInUp" data-wow-duration="2s" href="{{ pack.url }}">Pedir más Info &nbsp;
        <i class="fas fa-share"></i>
      </a>
    </div>

  </div>

</div>
