import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pack-audio',
  templateUrl: './pack-audio.component.html',
  styleUrls: ['./pack-audio.component.scss']
})
export class PackAudioComponent implements OnInit {

  logo = '../../../assets/img/logo-circle.png';
  title = 'WEBSYSFAN';
  ws: any = 'https://wa.me/51916949264?text=Hola%20deseo%20información%20de:';

  planes = [
    {
      title: ' Personal',
      year: ' 30',
      mounth: ' 4',
      oyentes: ' 700 Oyentes',
      autoDj: ' 256Mb de autoDJ',
      kbps: ' 128 KBPS',
      sofware: ' Sofware de transmisión',
      reproductor: ' Reproductor HTML',
      app: ' NO!',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20PERSONAL:',
    },
    {
      title: ' Básico',
      year: ' 60',
      mounth: ' 6',
      oyentes: ' 2.000 Oyentes',
      autoDj: ' 256Mb de autoDJ',
      kbps: ' 128 KBPS',
      sofware: ' Sofware de transmisión',
      reproductor: ' Reproductor HTML',
      app: ' NO!',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20BÁSICO:',
    },
    {
      title: ' Profesional',
      year: ' 80',
      mounth: ' 8',
      oyentes: ' 5.000 Oyentes',
      autoDj: ' 1GB de autoDJ',
      kbps: ' 192 KBPS',
      sofware: ' Sofware de transmisión',
      reproductor: ' Reproductor HTML',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20PROFESIONAL:',
    },
    {
      title: ' Corporativo',
      year: ' 100',
      mounth: ' 10',
      oyentes: ' 12.000 Oyentes',
      autoDj: ' 2GB de autoDJ',
      kbps: ' 192 KBPS',
      sofware: ' Sofware de transmisión',
      reproductor: ' Reproductor HTML',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20CORPORATIVO:',
    },
    {
      title: ' Profesional',
      year: ' 150',
      mounth: ' 15',
      oyentes: ' 25.000 Oyentes',
      autoDj: ' 4GB de autoDJ',
      kbps: ' 320 KBPS',
      sofware: ' Sofware de transmisión',
      reproductor: ' Reproductor HTML',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20PROFESIONAL:',
    },

  ]

  host: any = [
    {
      title: ' Básico',
      price: ' 190',
      oyentes: ' 2.000 Oyentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 512Mb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20BÁSICO:',
    },

    {
      title: ' Estándar',
      price: ' 310',
      oyentes: ' 5.000 Oyentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 1Gb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20ESTÁNDAR:',
    },

    {
      title: ' Corporativo',
      price: ' 500',
      oyentes: ' 15.000 Oyentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 2gB de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20CORPORATIVO:',
    },

    {
      title: ' Deluxe',
      price: ' 700',
      oyentes: ' 50.000 Oyentes conectados',
      web: ' Página web',
      diseno: ' Diseño personalizado',
      dominio: ' tudominio.com',
      subdominios: ' Subdominios ilimitados',
      cpanel: ' cPanel Personal',
      email: ' 10 Emails corporativos ',
      almacenamiento: ' 3Gb de autoDJ',
      sofware: ' Sofware de transmisión',
      adaptable: ' Adaptable a multipantallas',
      seo: ' Posicionamiento SEO',
      marketing: ' 1 mes de camapaña de marketing pagada en facebook',
      app: ' App android publicada en la PlayStore',
      capacitacion: ' 5 Horas de capacitación',
      Soporte: ' Soporte 24/7',
      url: 'https://wa.me/51916949264?text=Hola,%20deseo%20adquirir%20el%20PACK%20-%20DELUXE:',
    },
  ]

  incluye = [
    {name: 'Configuración de transmisión, remotamente'},
    {name: 'Instalacción herramientas de streaming'},
    {name: 'Posicionamiento SEO'},
    {name: 'Diseño 4 de Banners de página web'},
    {name: 'Diseño 3 de Banners para la Playstore'},
    {name: 'Administración Playstore'},
    {name: 'Certificado SSL'},
    {name: 'Enlace redes sociales'},
    {name: 'Mantenimiento'},
    {name: 'Cuentas FTP'},
    {name: 'Reproductor HTML'},
    {name: 'Ancho de Banda ILIMITADO'},
    {name: '10%  partir del segundo año, streaming de audio'},
    {name: '20% de descuento, a partir del segundo año, streaming más pagina web'},
    // {name: ''},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
