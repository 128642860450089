<div class="flyer">
  <img class="wow slideInDown" data-wow-duration="1.5s" [src]="flyer" alt="Flyer {{ title }}">
</div>

<main class="main">
  <section class="section">
    <h1 class="rosa wow slideInUp" data-wow-duration="1.5s" data-wow-delay=".5s"> {{ title }}</h1>
    <h3 class="wow slideInDown" data-wow-duration="1.5s" data-wow-delay=".5s">Diseño y Desarrollo de <br>
      <span class="rosa"><b>Aplicaciones Móviles </b></span>
      </h3>
    <h5 class="text-center mt-3 wow slideInDown" data-wow-duration="1.5s">Creamos plataformas utilizando las <br> últimas técnicas en <span class="rosa"> UX, CRO y mobile friendly</span></h5>

      <div class="apps default">
        <div class="bpxApp default">
          <img class="wow slideInRight" data-wow-duration="1.5s" [src]="app" alt="Images aplications {{ title }}">

        </div>
        <div class="bpxApp default">
          <h3 class="text-primary wow slideInDown" data-wow-duration="1.5s">Una app es la mejor opción para tu empresa</h3>
          <p class="mt-3 wow slideInLeft" data-wow-duration="1.5s">En <span class="rosa"> <b>{{ title }}</b></span> entendemos que la inversión que hace el empresario por el negocio es muy valiosa, por ello los productos que ofrecemos están desarrollados para mejorar su producción y crear una mejor relación con el usuario, <span class="rosa"> <b>enfocándonos en la rentabilidad de la empresa.</b></span></p>
        </div>
      </div>
      <h3 class="rosa wow slideInDown" data-wow-duration="1.5s">“No esperes más y realiza la aplicación que tanto deseas”</h3>

      <div class="apps default">
        <div class="bpxApp default">
          <div class="icons default centrar">
            <img class="wow slideInRight" data-wow-duration="1.5s" [src]="pc" alt="Images aplications {{ title }}">
          </div>
          <h3 class="mt-3 text-primary wow slideInDown" data-wow-duration="1.5s">Aplicaciones Web</h3>
           <p class="wow slideInRight" data-wow-duration="1.5s">Integra tus herramientas e información interactiva en una plataforma digital que se encuentre en internet, utlizando usuarios que podrán <span class="rosa"><b>compartir, recibir o subir informacón.</b></span></p>
           <p class="wow slideInRight" data-wow-duration="1.5s">Esto puede ayudar a <span class="rosa"><b>fidelizar a tus clientes a nivel de servicio,</b></span> mejorando la calidad de comunicación y el feedback que necesitas.</p>

           <a class="btn btn-outline-primary btn-lg mt-3 wow slideInDown" data-wow-duration="1.5s" href="{{ ws }}">  LO QUIERO </a>

        </div>
        <div class="bpxApp default">

          <div class="icons default centrar">
            <img class="wow slideInRight" data-wow-duration="1.5s" [src]="movile" alt="Images aplications {{ title }}">
          </div>
          <h3 class="mt-3 text-primary wow slideInDown" data-wow-duration="1.5s">Aplicaciones Móviles</h3>
           <p class="wow slideInLeft" data-wow-duration="1.5s">Son la mejor herramienta de fidelización, generando una excelente interación que posiciona a la marca con una imagen de innovación. Se pueden hacer para el consumidor final o apps internas para utilizar <span class="rosa"><b>compartir, recibir o subir informacón.</b></span></p>
           <p class="wow slideInLeft" data-wow-duration="1.5s">También permite realizar ofertas especiales hacia los clientes, <span class="rosa"><b>facilitar la compra tanto online como offline, obtener su opinión y motivar su recomendación en redes sociales.</b></span> </p>

           <a class="btn btn-outline-primary btn-lg mt-3 wow slideInDown" data-wow-duration="1.5s" href="{{ ws }}">LO DESEO</a>

        </div>
      </div>

      <div class="dev default">
        <div class="boxDev">
          <div class="left centrar wow slideInRight" data-wow-duration="1.5s">
            <i>Desarrollamos <br>
                la <span class="rosa"> <b>App</b></span> ideal <br>
                que necesitas.</i>
          </div>
        </div>
        <div class="boxDev">
          <div class="right wow slideInLeft" data-wow-duration="1.5s">
            <div class="devIcon default">
              <img [src]="car" alt="icons {{ title }}">
              <p>Tiendas Online</p>
            </div>
            <div class="devIcon default">
              <img [src]="pc" alt="icons {{ title }}">
              <p>Web Mobil</p>
            </div>
            <div class="devIcon default">
              <img [src]="personalizada" alt="icons {{ title }}">
              <p>Personalizadas</p>
            </div>
            <div class="devIcon default">
              <img [src]="intranet" alt="icons {{ title }}">
              <p>Intranets</p>
            </div>
            <div class="devIcon default">
              <img [src]="business" alt="icons {{ title }}">
              <p>Administración y Finanzas</p>
            </div>
            <div class="devIcon default">
              <img [src]="education" alt="icons {{ title }}">
              <p>Educación</p>
            </div>
            <div class="devIcon default">
              <img [src]="juegos" alt="icons {{ title }}">
              <p>Video juegos</p>
            </div>
          </div>
        </div>
      </div>

      <div class="apps default">
        <div class="bpxApp default">
          <div class="contSvg default centrar">
            <img class="wow slideInRight" data-wow-duration="1.5s" [src]="contratarnos" alt="Images aplications {{ title }}">
          </div>

        </div>
        <div class="check default">

          <h3 class="rosa mb-4 wow slideInDown" data-wow-duration="1.5s">Beneficios de contratarnos:</h3>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".1s"><i class="fas fa-check"></i><i> Desarrollamos en diversos lenguajes.</i></p>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".2s"><i class="fas fa-check"></i><i> Publicamos las apps en Playstore.</i></p>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".3s"><i class="fas fa-check"></i><i> UX y mobile friendly. </i></p>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".4s"><i class="fas fa-check"></i><i> Equipo de primer nivel.</i></p>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".5s"><i class="fas fa-check"></i><i> ¡Hosting gratis por un año! </i></p>
          <p class="wow slideInLeft" data-wow-duration="1.5s" data-wow-delay=".6s"><i class="fas fa-check"></i><i> Capacitación y guía personalizada.</i></p>

          <div class="centrar">
            <a class="btn btn-outline-success btn-lg mt-3 p-3 wow slideInDown" data-wow-duration="1.5s" href="{{ ws }}"> <b>EMPECEMOS AHORA</b></a>
          </div>

        </div>
      </div>



  </section>
</main>
