<div class="footer">
  <div class="childFooter">


    <div class="boxFooter">
      <div class=" text-center text-white-50">
        <a class="btn btn-success wow slideInUp" data-wow-duration="1.5s" target="_blank" href="{{ ws }}"><i class="fab fa-whatsapp"></i> +51 916 949 264</a> <br>
        <a href="mailto:websysfan@gmail.com"><i class="fas fa-envelope mt-3 text-white btn btn-outline-info wow slideInDown" data-wow-duration="1.5s"> websysfan@gmail.com </i></a>
      </div>
    </div>

  </div>



  <p class="text-center text-white-50 p-3 py-5">
      Copyright © 2016 - {{ anio }}, Todos los derechos reservados de <strong> {{ title }}</strong>
  </p>
</div>
