<div class="audio default">
  <div class="video">
    <div class="text">
      <h3>Steaming de Video</h3>
      <a class="btn btn-success btn-lg" href="{{ ws }}" target="_blank">Contacto</a>
    </div>

      <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">

      <source src="{{ video }}" type="video/mp4">
    </video>

  </div>
</div>

<main class="main">
  <section class="section">
    <div class="mt-5"><h3>STREAMING DE VIDEO</h3></div>
    <app-pack-video></app-pack-video>
  </section>
</main>

