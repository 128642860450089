import { ComponentsModule } from './../components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostingComponent } from './hosting/hosting.component';
import { SystemsComponent } from './systems/systems.component';
import { AppsComponent } from './apps/apps.component';
import { VPSComponent } from './vps/vps.component';
import { HomeComponent } from './home/home.component';
import { WebComponent } from './web/web.component';
import { SocialComponent } from './social/social.component';
import { StreamingAudioComponent } from './streaming-audio/streaming-audio.component';
import { StreamingVideoComponent } from './streaming-video/streaming-video.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';



@NgModule({
  declarations: [
    HostingComponent,
    SystemsComponent,
    AppsComponent,
    VPSComponent,
    HomeComponent,
    WebComponent,
    SocialComponent,
    StreamingAudioComponent,
    StreamingVideoComponent,
    EcommerceComponent,
  ],
  exports: [
    HostingComponent,
    SystemsComponent,
    AppsComponent,
    VPSComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule
  ]
})
export class PagesModule { }
